import { useUserStore } from '~/store/user/user.store';

/* Хранилище контроллера ежедневных бесплатных кейсов */
export const useGenerateUserLevelStore = defineStore('global/generateUserLevel', () => {
  const {
    $api: { mainPage: MainPageService },
  } = useNuxtApp();

  const userStore = useUserStore();

  const generateLevel = async () => {
    try {
      const result = await MainPageService.generateUserLevel();
      if (!result) return false;

      await userStore.getMe();
      return true;
    } catch {
      return false;
    }
  };

  return {
    generateLevel,
  };
});
